import React, { useState, useEffect } from "react";
import "../Styles/Home.css";
import { Navbar } from "../Core/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { getBanner, LatestProduct } from "../Services/Operations/ProductAPI";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Navigation, Pagination, Autoplay } from "swiper/modules";

function Home() {
  const navigate = useNavigate();
  const [banner, setBanner] = useState(null);
  const [latestProduct, setLatestProduct] = useState(null);

  useEffect(() => {
    const banner = async () => {
      try {
        const response = await getBanner();
        const response1 = await LatestProduct();
        console.log("Latest : ", response1.products);
        if (response.success === true) {
          setBanner(response.banner_list[0].banner_image_path);
        }
        if (response1.success === true) {
          setLatestProduct(response1.products);
        }
      } catch (error) {
        console.error("Error get profile:", error);
      }
    };
    banner();
  }, []);

  const handleProductClick = (product) => {
    console.log("product Id... ", product.id);
    if (product.product_url && product.id) {
      navigate(`/productDetails?product_url=${product.product_url}`, {
        state: { product_url: product.product_url },
      });
    } else {
      console.error("Product URL or ID is undefined");
    }
  };

  return (
    <div>
      <Navbar
        src1={require("../../Assets/Img/Vector.png")}
        src2={require("../../Assets/Img/material-symbols-light_search.png")}
        src3={require("../../Assets/Img/solar_cart-outline.png")}
      />
      <div className="home-container">
        <section className="header-banner">
          <img
            src={banner}
            alt="Banner"
            className="header-image"
            style={{ height: "100vh" }}
          />
          <div className="overlay-text">
            <p
              style={{
                fontFamily: "'Helvetica Neue', sans-serif",
                fontWeight: "700",
                fontStyle: "italic",
                fontSize: "21px",
              }}
            >
              do it loud
            </p>
            <button
              style={{
                fontFamily: "Arboria",
                fontSize: "20px",
                width: "114px",
                height: "41px",
                backgroundColor: "transparent",
                padding: "0px",
                border: "none",
                outline: "none",
                marginTop: "0px",
                fontWeight: "bold",
              }}
              onClick={() => navigate("/AllProduct")}
            >
              SHOP NOW
            </button>
          </div>
        </section>

        <section className="find-us-section">
          <h2
            style={{
              textAlign: "left",
              fontFamily: "Arboria",
              color: "#252338",
              fontWeight: "500",
              marginTop: "51px",
            }}
          >
            Latest Collection!
          </h2>

          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={10}
            autoplay={{ delay: 2000 }}
            navigation
            pagination={{ clickable: true }}
            style={{ padding: "20px" }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              500: {
                slidesPerView: 2,
              },
              680: {
                slidesPerView: 3,
              },
              900: {
                slidesPerView: 4,
              },
              1124: {
                slidesPerView: 5,
              },
              1224: {
                slidesPerView: 6,
              },
            }}
          >
            {latestProduct?.map((product, index) => (
              <SwiperSlide key={index}>
                <div
                  className="image-placeholder"
                  onClick={() => handleProductClick(product)}
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={product.image_path}
                    alt="Product"
                    style={{
                      width: "100%",
                      height: "220px",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                  <p
                    style={{
                      fontFamily: "Arboria",
                      color: "black",
                      textTransform: "uppercase",
                      margin: "8px 0 0",
                      padding:"0px 12px"
                    }}
                  >
                    {product.product_name}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </section>

        <footer className="store-policy">
          <Link to="/store-policy">
            <h3 style={{ color: "#252338" }}>STORE POLICY</h3>
          </Link>
          <ul>
            <Link to="/term-services">
              <li style={{ color: "#252338" }}>TERMS OF SERVICE</li>
            </Link>
            <Link to="/shipping-policy">
              <li style={{ color: "#252338" }}>SHIPPING AND EXCHANGE</li>
            </Link>
            <Link to="/Track-order">
              <li style={{ color: "#252338" }}>TRACK YOUR ORDER</li>
            </Link>
          </ul>
        </footer>
      </div>
      <div
        style={{
          fontFamily: "Arboria",
          color: "#DEBBA2",
          width: "100%",
          textAlign: "center",
          marginTop: "73px",
          marginBottom: "38px",
        }}
        className="footer-support"
      >
        <h3
          style={{
            color: "#DEBBA2",
          }}
        >
          (c) 2024 overbar, all rights resered
        </h3>
        <p>
          Tech support by -{" "}
          <span style={{ color: "#DEBBA2", fontWeight: "600" }}>
            Siance Software Pvt Ltd
          </span>{" "}
        </p>
      </div>
    </div>
  );
}

export default Home;
