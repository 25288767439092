import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "lodash";
import "../Styles/Navbar.css";
import { IoMenuSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { FaAngleUp, FaAngleDown } from "react-icons/fa6";
import {
  getCategory,
  getCoupon,
  search_api,
} from "../Services/Operations/ProductAPI";
import { useSelector } from "react-redux";
import { FaFacebookF, FaInstagram, FaPinterestP } from "react-icons/fa";
import axios from "axios";
import logo from "../../Assets/Wordmark Black.png";
import logoWhite from "../../Assets/Wordmark White.png";
import { CiUser } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";

export const Navbar = ({ color = "white", src1, src2, src3 }) => {
  const navigate = useNavigate();
  const Header = [
    { title: "products", path: "#" },
    { title: "new in", path: "#" },
    { title: "our story", path: "/about" },
    { title: "OB connect", path: "/Contact" },
    { title: "track my order", path: "/Track-order" },
  ];

  const [mobileIcon, setMobileIcon] = useState(false);
  const [mobileCross, setMobileCross] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [category, setCategory] = useState([]);
  const [coupon, setCoupon] = useState([]);
  const submenuRef = useRef(null);

  const [searchOpen, setSearchOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentCouponIndex, setCurrentCouponIndex] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const [nextCouponIndex, setNextCouponIndex] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Debounced search function
  const debouncedSearch = useCallback(
    _.debounce(async (searchQuery) => {
      if (searchQuery.trim() === "") {
        setResults([]);
        return;
      }
      setLoading(true);
      setError(null);
      try {
        console.log("Searching for:", searchQuery);
        const response = await search_api(searchQuery);
        console.log("Search data:", response.subcategory_list);
        if (response && response.subcategory_list) {
          setResults(response.subcategory_list);
        } else {
          setResults([]);
        }
      } catch (err) {
        console.error("Search error:", err);
        setError("An error occurred while searching. Please try again.");
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  const handleSearch = (text) => {
    console.log("Handling search with:", text);
    setQuery(text);
    debouncedSearch(text);
  };

  const toggleSearch = () => {
    setSearchOpen((prev) => {
      const isOpen = !prev;
      if (!isOpen) {
        setQuery("");
        setResults([]);
      }
      return isOpen;
    });
  };

  const navigateToProduct = (item) => {
    setQuery("");
    setResults([]);
    navigate(`/AllProduct?subcategory_id=${item.id}`, {
      state: { subcategory_id: item.subcategory_name },
    });
  };

  const callSearchAPI = (query) => {
    console.log("data : ", query);
    navigate(`/AllProduct?product_url=${query}`, {
      state: { product_url: query },
    });
  };

  const totalItems = useSelector((state) => state.cart.totalItems);
  console.log("totalItems : ", totalItems);

  useEffect(() => {
    const userCategory = async () => {
      try {
        const response = await getCategory();
        const response1 = await getCoupon();
        console.log("Coupon : ", response1);
        if (response.success === true) {
          setCategory(response.subcategory);
          setCoupon(response1.coupon);
          console.log("........", response1.coupon);
        }
      } catch (error) {
        console.error("Error get profile:", error);
      }
    };
    userCategory();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 700);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Close submenu if clicked outside
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setActiveSubmenu(null); // Close submenu
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [submenuRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % coupon.length);
    }, 4000); // Change text every 4 seconds
    return () => clearInterval(interval);
  }, [coupon.length]);

  const handleCart = () => {
    navigate("/Cart");
  };

  const handlerIcon = () => {
    setMobileIcon((prev) => !prev);
    setMobileCross(false);
    setActiveSubmenu(null);
  };

  const handlerCrossIcon = () => {
    setMobileCross((prev) => !prev);
    setMobileIcon(false);
    setMobileCross(false);
    setActiveSubmenu(null);
  };

  const toggleSubmenu = (index) => {
    setActiveSubmenu((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        className="coupon-container"
        style={{
          width: "100%",
          height: "34px",
          backgroundColor: "black",
          color: "white",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {/* <div className="social-media-icon">
          <Link
            to="https://www.facebook.com/overbarstudios"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            <FaFacebookF size={30} />
          </Link>
          <Link
            to="https://www.instagram.com/overbar.stu/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            <FaInstagram size={30} />
          </Link>
          <Link
            to="https://in.pinterest.com/overbarstudios/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "white" }}
          >
            <FaPinterestP size={30} />
          </Link>
        </div> */}
        {coupon.map((item, index) => (
          <p
            key={item.id}
            className={`coupon ${index === currentIndex ? "enter" : "exit"}`}
            style={{ fontSize: "15px", marginBottom: "0px" }}
          >
            {item.discount}% OFF CODE ({item.coupon_code})
          </p>
        ))}
      </div>

      <div
        className={`${
          mobileIcon ? "container1 mobile-container1" : "container1"
        }`}
        style={{
          width: "100%",
          padding: "10px 20px",
          position: "absolute",
          top: "11px",
          zIndex: "1000",
          color: "white",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="contain-menu" onClick={handlerIcon}>
          <IoMenuSharp style={{ width: "24px", height: "24px", color }} />
          <img
            src={isMobile ? logoWhite : logo}
            alt="Logo"
            onClick={() => {
              navigate("/");
            }}
            style={{ cursor: "pointer", width: "100px", marginLeft: "10px" }}
          />
        </div>
        <div style={{ display: "flex", gap: "18px", position: "relative" }}>
          {/* <img
            style={{ width: "17px", height: "15.58px" }}
            src={src1}
            alt="Logo"
            onClick={() => {
              navigate("/Profile");
            }}
          />
          <img
            style={{ width: "17px", height: "15.58px" }}
            src={src2}
            alt="Logo"
            onClick={toggleSearch}
          /> */}
          <CiUser
            style={{ width: "17px", height: "15.58px",color:color }}
            onClick={() => {
              navigate("/Profile");
            }}
          />

          <IoIosSearch
            style={{ width: "17px", height: "15.58px",color:color }}
            onClick={toggleSearch}
          />
          <img
            style={{ width: "17px", height: "15.58px" }}
            src={src3}
            alt="Logo"
            onClick={handleCart}
          />
          <p
            style={{
              position: "absolute",
              top: "-12px",
              right: "-9px",
              backgroundColor: "black",
              color: " white",
              marginBottom: "0px",
              width: "19px",
              textAlign: "center",
              borderRadius: "50%",
              fontSize: "14px",
            }}
          >
            {totalItems > 0 ? totalItems : ""}
          </p>
        </div>
        {searchOpen && (
          <div className="search-bar3">
            <input
              placeholder="Search..."
              value={query}
              onChange={(e) => handleSearch(e.target.value)}
            ></input>
            <button
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
              }}
              onClick={toggleSearch}
            >
              X
            </button>
          </div>
        )}
        {results && results.length > 0 && (
          <>
            {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            <ul className="result-list3">
              {results.map((item) => (
                <li key={item.id} onClick={() => navigateToProduct(item)}>
                  {item.subcategory_name}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className="contain-nav">
        <div className="holiday-image">
          <img
            src={logo}
            alt="Logo"
            onClick={() => {
              navigate("/");
            }}
            style={{ cursor: "pointer", width: "100px", marginLeft: "20px" }}
          />
        </div>
        <div
          className={`${mobileIcon ? "main-nav mobile-main-nav" : "main-nav"} ${
            mobileCross ? "mobile-cross-icon" : "mobile-cross-icon1"
          }`}
        >
          {/* <div
            className={`${
              mobileIcon
                ? "social-media-icon1 moile_social-media-icon1"
                : "social-media-icon1"
            }`}
          >
            <div style={{ position: "relative", display: "flex", gap: "14px" }}>
              <Link
                to="https://www.facebook.com/overbarstudios"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black" }}
              >
                <FaFacebookF size={30} />
              </Link>
              <Link
                to="https://www.instagram.com/overbar.stu/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black" }}
              >
                <FaInstagram size={30} />
              </Link>
              <Link
                to="https://in.pinterest.com/overbarstudios/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "black" }}
              >
                <FaPinterestP size={30} />
              </Link>
            </div>
          </div> */}
          <div
            className={`${mobileIcon ? "main-menu mobile-menu" : "main-menu"}`}
          >
            <button onClick={handlerCrossIcon} style={{backgroundColor: "transparent"}}>
              <RxCross1 />
            </button>
            <div style={{ display: "flex", gap: "18px", position: "relative" }}>
              {/* <img
                style={{ width: "17px", height: "15.58px" }}
                src={require("../../Assets/Img/Vector(1).png")}
                alt="Logo"
                onClick={() => {
                  navigate("/Profile");
                }}
              /> */}
              <CiUser
                style={{ width: "17px", height: "15.58px", color: "black" }}
                onClick={() => {
                  navigate("/Profile");
                }}
              />
              {/* <img
                style={{ width: "17px", height: "15.58px" }}
                src={require("../../Assets/Img/material-symbols-light_search(1).png")}
                alt="Logo"
                onClick={toggleSearch}
              /> */}
              <IoIosSearch
                style={{ width: "17px", height: "15.58px", color: "black" }}
                onClick={toggleSearch}
              />
              <img
                style={{ width: "17px", height: "15.58px" }}
                src={require("../../Assets/Img/solar_cart-outline(1).png")}
                alt="Logo"
                onClick={handleCart}
              />
              <p
                style={{
                  position: "absolute",
                  top: "-12px",
                  right: "-9px",
                  backgroundColor: "black",
                  color: " white",
                  marginBottom: "0px",
                  width: "19px",
                  textAlign: "center",
                  borderRadius: "50%",
                  fontSize: "14px",
                }}
              >
                {totalItems > 0 ? totalItems : ""}
              </p>
            </div>
            {searchOpen && (
              <div className="search-bar1">
                <input
                  placeholder="Search..."
                  value={query}
                  onChange={(e) => handleSearch(e.target.value)}
                ></input>
                <button
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                  }}
                  onClick={toggleSearch}
                >
                  X
                </button>
              </div>
            )}
            {results && results.length > 0 && (
              <>
                {loading && <p>Loading...</p>}
                {error && <p>{error}</p>}
                <ul className="result-list1">
                  {results.map((item) => (
                    <li key={item.id} onClick={() => navigateToProduct(item)}>
                      {item.subcategory_name}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>

          <div style={{ display: "flex", gap: "31px" }}>
            <ul className={`${mobileIcon ? "menus mobile-menus" : "menus"}`}>
              {Header.map((item, index) => (
                <li
                  key={index}
                  className={`${
                    mobileIcon ? "menu-item mobile-menu-item" : "menu-item"
                  }`}
                  ref={item.title === "products" ? submenuRef : null}
                >
                  <div
                    className={`${
                      mobileIcon ? "menu-item mobile-menu-item" : "menu-item"
                    }`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <Link
                      to={item.path}
                      onClick={() => toggleSubmenu(index)}
                      style={{
                        fontFamily: "Helvetica Neue",
                        fontStyle: "italic",
                        fontWeight: "bolder",
                      }}
                    >
                      {item.title}
                    </Link>

                    {item.title === "products" && category.length > 0 && (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleSubmenu(index);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {activeSubmenu === index ? (
                          <FaAngleUp />
                        ) : (
                          <FaAngleDown />
                        )}
                      </span>
                    )}
                  </div>

                  {/* Submenu */}
                  {item.title === "products" &&
                    category.length > 0 &&
                    activeSubmenu === index && (
                      <ul
                        className={`${
                          mobileIcon ? "submenu mobile-submenu" : "submenu"
                        }`}
                      >
                        <li onClick={() => toggleSubmenu(index)}>
                          <Link
                            to="/AllProduct"
                            onClick={() => setMobileIcon(false)}
                            style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            SHOP ALL
                          </Link>
                        </li>
                        {category.map((catItem, subIndex) => (
                          <li
                            key={subIndex}
                            onClick={() => toggleSubmenu(index)}
                          >
                            <Link
                              to={`/AllProduct?subcategory_id=${catItem.id}`}
                              onClick={() => setMobileIcon(false)}
                              style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              {catItem.subcategory_name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>

            <div className="webView-icon" style={{ gap: "18px" }}>
              {/* <img
                style={{ width: "17px", height: "15.58px" }}
                src={require("../../Assets/Img/Vector(1).png")}
                alt="Logo"
                onClick={() => {
                  navigate("/Profile");
                }}
              />
              <img
                style={{ width: "17px", height: "15.58px" }}
                src={require("../../Assets/Img/material-symbols-light_search(1).png")}
                alt="Logo"
                onClick={toggleSearch}
              /> */}
              <CiUser
                style={{ width: "17px", height: "15.58px", color: "black" }}
                onClick={() => {
                  navigate("/Profile");
                }}
              />
              <IoIosSearch
                style={{ width: "17px", height: "15.58px", color: "black" }}
                onClick={toggleSearch}
              />
              <img
                style={{ width: "17px", height: "15.58px" }}
                src={require("../../Assets/Img/solar_cart-outline(1).png")}
                alt="Logo"
                onClick={handleCart}
              />
              <p
                style={{
                  position: "absolute",
                  top: "33px",
                  right: "11px",
                  backgroundColor: "black",
                  color: " white",
                  marginBottom: "0px",
                  width: "20px",
                  textAlign: "center",
                  borderRadius: "50%",
                  fontSize: "14px",
                }}
              >
                {totalItems > 0 ? totalItems : ""}
              </p>
            </div>
            {searchOpen && (
              <div
                className={`${
                  mobileIcon ? "search-bar2 mobile-search-bar2" : "search-bar2"
                }`}
              >
                <input
                  placeholder="Search..."
                  value={query}
                  onChange={(e) => handleSearch(e.target.value)}
                >
                  {/* <option value="" disabled>
                    Select a category
                  </option>
                  <option value="category1">Category 1</option>
                  <option value="category2">Category 2</option>
                  <option value="category3">Category 3</option> */}
                </input>
                <button
                  style={{
                    border: "none",
                    outline: "none",
                    backgroundColor: "transparent",
                  }}
                  onClick={toggleSearch}
                >
                  X
                </button>
              </div>
            )}
            {results && results.length > 0 && (
              <>
                {loading && <p>Loading...</p>}
                {error && <p>{error}</p>}
                <ul
                  className={`${
                    mobileIcon
                      ? "result-list2 mobile-result-list2"
                      : "result-list2"
                  }`}
                >
                  {results.map((item) => (
                    <li key={item.id} onClick={() => navigateToProduct(item)}>
                      {item.subcategory_name}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
