const baseURL = 'https://sianceedu.com/overbar/api/';

//Signup endpoint
export const Signup_endpoint={
    SIGNUP_API:baseURL+"sign-up/send-otp", 
    OTP_API:baseURL+"sign-up/verify-otp", 
}

//Logout endpoint
export const Logout_endpoint={
    LOGOUT_API:baseURL+"logout", 
}

//Coupon endpoint
export const Coupon_endpoint={
    COUPON_API:baseURL+"coupon", 
    BANNER_API:baseURL+"banner-list", 
}

//Category endpoint
export const Category_endpoint={
    CATEGORY_API:baseURL+"sub-category",
    CATEGORYPRODUCT_API:baseURL+"subcategory-wise-product", 
}

//Product endpoint
export const Product_endpoint={
    PRODUCT_API:baseURL+"product-list", 
    PRODUCT_DETAILS_API:baseURL+"product-details",
}

//Place order
export const PlaceOrder_endpoint={
    PLACEORDER_API:baseURL+"place-order", 
}

//State order
export const state_endpoint={
    state_endpoint_API:baseURL+"all-state", 
}

//order create
export const orderCreate_endpoint={
    order_endpoint_API:baseURL+"place-order",
    orderValidate_endpoint_API:baseURL+"verify-payment", 
    orderValidate_endpoint_cod_API:baseURL+"verify-payment-cod",
    singleOrder_endpoint_API:baseURL+"order-details", 
}

//Profile
export const profile_endpoint={
    PROFILE_endpoint_API:baseURL+"user-details",
    PROFILEUPDATE_endpoint_API:baseURL+"update-profile",
}

//Cancel Order
export const CancelOrder_endpoint={
    CANCELORDER_endpoint_API:baseURL+"cancel-order",
}

//Coupon
export const checkCoupon_endpoint={
    COUPON_endpoint_API:baseURL+"chk-coupon",
}

//Contact mail
export const contact_endpoint={
    CONTACT_endpoint_API:baseURL+"contact",
}

//Search by Product
export const search_endpoint={
    search_endpoint_API:baseURL+"search-subcategory",
}

//Latest Product
export const Latest_endpoint={
    latest_endpoint_API:baseURL+"latest-products",
}