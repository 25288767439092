import React from "react";
import { Navbar } from "../Core/Navbar";
import { Link } from "react-router-dom";

export const About = () => {
  return (
    <div>
      <Navbar
        color="black"
        src1={require("../../Assets/Img/Vector(1).png")}
        src2={require("../../Assets/Img/material-symbols-light_search(1).png")}
        src3={require("../../Assets/Img/solar_cart-outline(1).png")}
      />
      {/* <section class="page-add">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="page-breadcrumb">
                                <h2>Shirts<span>.</span></h2>
                                <Link to="#">Home</Link>
                                <Link to="#">Dresses</Link>
                                <Link class="active" to="#">Night Dresses</Link>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <img src={require('../../Assets/add.jpg')} alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section class="product-page">
                <div class="container">
                    <div class="product-control">
                        <Link to="#">Previous</Link>
                        <Link to="#">Next</Link>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="product-slider owl-carousel">
                                <div class="product-img">
                                    <figure>
                                        <img src={require('../../Assets/product/product-1.jpg')} alt="" />
                                        <div class="p-status">new</div>
                                    </figure>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <div class="product-content">
                                <h2>Dotted Blue Shirt</h2>
                                <div class="pc-meta">
                                    <h5>$22.90</h5>
                                    <div class="rating">
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                        <i class="fa fa-star"></i>
                                    </div>
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo
                                    viverra maecenas accumsan lacus vel facilisis.</p>
                                <ul class="tags">
                                    <li><span>Category :</span> Men’s Wear</li>
                                    <li><span>Tags :</span> man, shirt, dotted, elegant, cool</li>
                                </ul>
                                <div class="product-quantity">
                                    <div class="pro-qty">
                                        <input type="text" value="1" />
                                    </div>
                                </div>
                                <Link to="#" class="primary-btn pc-btn">Add to cart</Link>
                                <ul class="p-info">
                                    <li>Product Information</li>
                                    <li>Reviews</li>
                                    <li>Product Care</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="related-product spad">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 text-center">
                            <div class="section-title">
                                <h2>Related Products</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-product-item">
                                <figure>
                                    <Link to="#"><img src={require('../../Assets/products/img-1.jpg')} alt="" /></Link>
                                    <div class="p-status">new</div>
                                </figure>
                                <div class="product-text">
                                    <h6>Green Dress with details</h6>
                                    <p>$22.90</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-product-item">
                                <figure>
                                    <Link to="#"><img src={require('../../Assets/products/img-2.jpg')} alt="" /></Link>
                                    <div class="p-status sale">sale</div>
                                </figure>
                                <div class="product-text">
                                    <h6>Yellow Maxi Dress</h6>
                                    <p>$25.90</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-product-item">
                                <figure>
                                    <Link to="#"><img src={require('../../Assets/products/img-3.jpg')} alt="" /></Link>
                                    <div class="p-status">new</div>
                                </figure>
                                <div class="product-text">
                                    <h6>One piece bodysuit</h6>
                                    <p>$19.90</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-product-item">
                                <figure>
                                    <Link to="#"><img src={require('../../Assets/products/img-4.jpg')} alt="" /></Link>
                                    <div class="p-status popular">popular</div>
                                </figure>
                                <div class="product-text">
                                    <h6>Blue Dress with details</h6>
                                    <p>$35.50</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "0px",
        }}
      >
        <nav
          className="breadcrumb"
          style={{
            marginBottom: "0px",
            fontFamily: "Arboria",
            display: "flex",
            gap: "4px",
            alignItems: "center",
            color:"#EED7A6",
            backgroundColor:"#1E1D21"
          }}
        >
          <span style={{color:"#EED7A6",}}>HOME</span> &gt; <span style={{color:"#EED7A6",}}>OUR STORY</span>
        </nav>

        <div className="main-section" style={{ margin: "0px" }}>
          <img
            src={require("../../Assets/Img/Item.png")}
            alt="OB Connect"
            className="main-image"
            style={{ height: "437px", objectFit: "fill", borderRadius: "0px" }}
          />
          <div>
            <h1
              className="main-title"
              style={{
                fontFamily: "Helvetica Neue",
                fontStyle: "italic",
                fontWeight: "bold",
                fontSize: "36px",
                textAlign: "left",
                paddingLeft: "30px",
                color:"#252338",
                letterSpacing: "-3.5px"
              }}
            >
              about overbar
            </h1>
            <p
              className="main-description contact-text"
              style={{
                lineHeight: "15px",
                textAlign: "left",
                padding: "0px 35px",
                textAlign:"justify",
                 marginLeft: "4px",
                 textTransform:"uppercase"
              }}
            >
              In 2021, we embarked on this journey, captivated by the vibrant
              tie-dye trends that swept through the fashion scene. Our humble
              beginnings were centered around crafting tie-dye t-shirts, riding
              the wave of excitement that surrounded this artful expression.
              However, as time unfolded, so did our desire for innovation and
              uniqueness.
            </p>
          </div>
          <div>
            <h1
              className="main-title"
              style={{
                fontFamily: "Helvetica Neue",
                fontStyle: "italic",
                fontWeight: "bold",
                fontSize: "36px",
                textAlign: "left",
                paddingLeft: "30px",
                color:"#252338",
                letterSpacing: "-3.5px"
              }}
            >
              our story
            </h1>
            <p
              className="main-description contact-text"
              style={{
                lineHeight: "15px",
                textAlign: "left",
                padding: "0px 35px",
                textAlign:"justify",
                marginLeft: "4px",
                textTransform:"uppercase"
              }}
            >
              After a year of exclusively offering t-shirts, we found ourselves
              yearning for something more - a chance to experiment, to create,
              and to connect. Our exploration led us to sample diverse clothing
              pieces, experimenting with an array of prints and embracing
              different fits. It was a transformative period for us.
            </p>
            <p
              className="main-description contact-text"
              style={{
                lineHeight: "15px",
                textAlign: "left",
                padding: "0px 35px",
                textAlign:"justify",
                 marginLeft: "4px",
                 textTransform:"uppercase"
              }}
            >
              Beyond the confines of fleeting trends, we've evolved into a brand
              that is more than just clothing; we're about building a community.
              Our focus has shifted from keeping up with the latest industry
              trends to forging our own path. We're not just designing apparel;
              we're creating an identity. This is more than a brand; it's an
              invitation to join a community that appreciates uniqueness,
              embraces creativity, and celebrates the journey of
              self-expression. Welcome to our evolving story, where every stitch
              and print is a testament to the shared spirit of individuality and
              community.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
