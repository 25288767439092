import React, { useState, useEffect } from "react";
import { Navbar } from "../../Core/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../Styles/products.css";
import {
  getCategory,
  getCategoryProduct,
  getProduct,
} from "../../Services/Operations/ProductAPI";

export const Product = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subcategoryId = searchParams.get("subcategory_id");
  const [sortOption, setSortOption] = useState("ALL");
  const selectedSubcategoryId = searchParams.get("subcategory_id");

  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const userCategory = async () => {
      setLoading(true); // Start loading when fetching data
      try {
        const response = await getCategory();
        if (!subcategoryId) {
          const response1 = await getProduct(sortOption);
          console.log("product data", response1);
          if (response1.success === true) {
            setProduct(response1.products);
          } else {
            setProduct([]); // Set empty array if no products are found
          }
        } else {
          const response1 = await getCategoryProduct(subcategoryId);
          console.log("product data", response1);
          if (response1.success === true) {
            setProduct(response1.products);
          } else {
            setProduct([]); // Set empty array if no products are found
          }
        }

        if (response.success === true) {
          setCategory(response.subcategory);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setProduct([]); // Handle error by setting product as empty array
      } finally {
        setLoading(false); // Stop loading once data is fetched or error occurred
      }
    };
    userCategory();
  }, [subcategoryId, sortOption]);

  const handleProductClick = (product) => {
    console.log("product Id... ", product.id);
    if (product.product_url && product.id) {
      navigate(`/productDetails?product_url=${product.product_url}`, {
        state: { product_url: product.product_url },
      });
    } else {
      console.error("Product URL or ID is undefined");
    }
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  return (
    <div>
      <Navbar
        color="black"
        src1={require("../../../Assets/Img/Vector(1).png")}
        src2={require("../../../Assets/Img/material-symbols-light_search(1).png")}
        src3={require("../../../Assets/Img/solar_cart-outline(1).png")}
      />
      <div style={{ flexDirection: "column", fontFamily: "Arboria" }}>
        <header className="Header" style={{ width: "100%" }}>
          <nav className="navbar" style={{ width: "100%" }}>
            <Link
              to="/AllProduct"
              style={{
                color:
                  location.pathname === "/AllProduct" && !selectedSubcategoryId
                    ? "#FC8630"
                    : "#EED7A6",
              }}
            >
              SHOP ALL
            </Link>
            {category.map((catItem, subIndex) => (
              <li key={subIndex}>
                <Link
                  to={`/AllProduct?subcategory_id=${catItem.id}`}
                  style={{
                    color:
                      selectedSubcategoryId === String(catItem.id)
                        ? "#FC8630"
                        : "#EED7A6",
                  }}
                >
                  {catItem.subcategory_name}
                </Link>
              </li>
            ))}
          </nav>
          <h1
            style={{
              marginTop: "20px",
              fontSize: "36px",
              textAlign: "left",
              paddingLeft: "20px",
              fontFamily: "Helvetica Neue",
              fontStyle: "italic",
              fontWeight: "bold",
              letterSpacing: "-3.5px",
            }}
          >
            All Products
          </h1>
          <div className="sort-section">
            <select value={sortOption} onChange={handleSortChange}>
              <option value="ALL">SORT BY</option>
              <option value="BESTSELLING">BESTSELLING</option>
              <option value="A-Z">ALPHABETICALLY, A-Z</option>
              <option value="Z-A">ALPHABETICALLY, Z-A</option>
              <option value="LowToHigh">PRICE, LOW TO HIGH</option>
              <option value="HighToLow">PRICE, HIGH TO LOW</option>
            </select>
          </div>
        </header>

        {/* Loading State */}
        {loading ? (
          <p>Loading products...</p>
        ) : product.length > 0 ? (
          <div className="product-grid">
            {product.map((prod) => (
              <div
                key={prod.id}
                className="product-card"
                onClick={() => handleProductClick(prod)}
              >
                <img
                  src={prod.image_path}
                  alt={prod.name}
                  style={{ height: "289px", objectFit: "cover" }}
                />
                <h2
                  style={{
                    fontFamily: "Arboria",
                    color: "black",
                    textTransform: "uppercase",
                    marginBottom: "0px",
                  }}
                >
                  {prod.product_name}
                </h2>
                <p style={{ fontFamily: "Arboria", color: "black" }}>
                  ₹ {prod.mrp}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <p
            style={{
              textAlign: "center",
              fontSize: "25px",
              marginTop: "135px",
            }}
          >
            No products found
          </p>
        )}
      </div>
    </div>
  );
};
